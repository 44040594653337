import { graphql, HeadFC, navigate } from "gatsby";
import parse from "html-react-parser";
import { throttle, uniqueId } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../components/common/Breadcrumb";
import ImageGallery from "../../components/common/ImageGallery";
import PageLoader from "../../components/common/Loader/PageLoader";
import ProductDetailsLoader from "../../components/common/Loader/ProductDetailsLoader";
import ProductLongLoader from "../../components/common/Loader/ProductLongLoader";
import ProductDetailsCounter from "../../components/common/ProductDetailsCounter";
import TruncatedHtml from "../../components/common/TruncatedHtml/TruncatedHtml";
import Layout from "../../components/layout/Layout";
import ProductCarousel from "../../components/Products/ProductCarousel";
import Varient from "../../components/Products/Varient";
import "react-multi-carousel/lib/styles.css";
import StarReview from "../../components/Review/StarReview";
import Seo from "../../components/Seo";
import { AppDispatch, RootState } from "../../store";
import { checkAndAddToCart, slideToggle } from "../../store/cartSlice";
import { Crumb, Product as ProductType } from "../../types";
import "./ProDetails.css";
import useJWTChecker from "../../utils/Custom-hook/useJWTChecker";
import { calculatePriceRange, capitalizeFirstLetter } from "../../utils/helper";
import visa from "../../../src/assets/images/visa-card.png";
import vimeo from "../../../src/assets/images/vimeo.png";
import masterCard from "../../../src/assets/images/master-card.png";
import { toast } from "react-toastify";
import { useLocation } from "@reach/router";
import { Lock, PPolicy, SeaSame, Shipping } from "../../components/common/Svg";

export default function Product(props: any) {
  const location = useLocation();
  const { data } = props;
  const { productAdditionalData } = data;
  const secret = process.env.GATSBY_YOTPO_APPKEY;

  const { userId } = useJWTChecker();
  const dispatch = useDispatch<AppDispatch>();
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);
  const [image, setImage] = useState("");
  const [product, setProduct] = useState<any>(data.data);
  const [productPrice, setProductPrice] = useState("");
  const [productPricelimit, setProductPriceLimit] = useState<string>("");

  const [varients, setVarient] = useState<any>([]);
  const [relatedProduct, setRelatedProduct] = useState<any>([]);
  const [selectedVarient, setSelectedVarient] = useState<any>({});
  const [groupedProduct, setGroupedProduct] = useState<ProductType[]>([]);
  const [priceRange, setPriceRange] = useState<any>({});
  const [seoInfo, setSeoInfo] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);
  const [inputValue, setInputValue] = useState<string | number>(1);
  const varButtonRef = useRef<HTMLDivElement>(null);
  const productDetailsSecRef = useRef<HTMLDivElement>(null);
  const [Block, setblock] = useState(false);
  const [option, setOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [allVarient, setAllVarients] = useState();
  const [additionalData, setAdditionalData] = useState<any>(null);

  const [selectedOptions, setSelectedOptions] = useState<any>({});
  const [selectedProductPrice, setSelectedProductPrice] = useState<any>({});

  // const [loadFeatured, setLoadFeatured] = React.useState(false);
  const [allImages, setAllImages] = React.useState<any>(null);
  const [videoDetails, setVideoDetails] = React.useState<any>(false);

  useEffect(() => {

    // if (props.data.data.stock_status === "outofstock") {
    //   navigate(`/`);

    // }
    // setTimeout(() => {
    // setLoadFeatured(true);
    // }, 5000);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    console.log(window.location);
    // console.log(urlParams, "urlParamsurlParamsurlParamsurlParamsurlParamsurlParamsurlParamsurlParamsurlParams")
    // if (urlParams.has("size")) {
    //   navigate(`/`);
    // }

    if (urlParams.has("q")) {
      urlParams.delete("q");

      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState({}, document.title, newUrl);

      console.log("The 'q' parameter has been removed from the URL.");
    }
  }, []);

  const handleVarientChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    setSelectedOption(value);
    const varientdata = await processProduct(allVarient, value);
    setSelectedVarient(varientdata[0]);
    setProductPrice(
      varientdata?.[0]?.sale_price || varientdata?.[0]?.regular_price
    );

    setVarient(varientdata);

    //VARIENT CONSOLE
    const allimages = varientdata.map((p: any) => {
      return p.image;
    });
    const imagesData: any = [allimages[0]];
    if (videoDetails) {
      imagesData.push(videoDetails);
      setAllImages(imagesData);
    } else {
      setAllImages(imagesData);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://staticw2.yotpo.com/${secret}/widget.js`;
    script.async = true;
    script.type = "text/javascript";
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    if (window.yotpo) {
      // update Yotpo dynamically
      window.yotpo.initialized = false;
      window?.yotpo?.clean();
      // there is a widgets array that the widgets are pulling data from; we need to update this
      for (let i = 0, len = window.yotpo.widgets.length; i < len; i++) {
        window.yotpo.widgets[i].settings.pid = product.id;
        window.yotpo.widgets[i].settings.main_widget_pid = product.id;
      }
      if (typeof window["yotpo"] !== "undefined") {
        window["yotpo"].initWidgets();
      }
    }
    // }, 2000);

    const stateData = props.location.state;
    const categoryName = stateData?.categoryName;
    const categorySlug = stateData?.categorySlug;
    const productName = stateData?.productName;
    if (categoryName) {
      setCrumbs([
        { label: categoryName, link: `/product-category/${categorySlug}` },
        { label: productName, link: null },
      ]);
    } else {
      setCrumbs([
        {
          label: product?.categories?.[0].name,
          link: `/product-category/${product.categories?.[0].slug}`,
        },
        { label: product?.name, link: null },
      ]);
    }
    // if (product?.slug) {
    // getHead(`product/${props?.location?.pathname?.split("/")[2]}`).then((data: any) => {
    //   setSeoInfo(data.head);
    // })
    // }
  }, [props, product]);

  const cart = useSelector((state: RootState) => state.cart);

  useEffect(() => {
    const fetchProducts = async () => {
      function getYouTubeVideoID(url: any) {
        const regExp =
          /^.*(youtu\.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|\&v=|watch\?vi=)([^#\&\?]*).*/;
        const match = url?.match(regExp);

        if (match && match[2].length === 11) {
          return match[2];
        } else {
          return null; // If no video ID is found
        }
      }

      let initialImage: any;

      // setProduct(data.data);

      setImage(data.data?.images?.[0]?.src);
      // NEW SECTION ADDED
      // "discounted-upsell-products"
      const discountProductFlag = data.data.categories.some(
        (data: any) => data.slug == "discounted-upsell-products"
      );
      // IF TRUE THEN PRODUCT IS DISCOUNTED
      if (discountProductFlag) {
        const mainProductId =
          data.productAdditionalData.originProduct.mainProduct.nodes[0]
            .databaseId;
        setAdditionalData(
          data.productAdditionalData.originProduct.mainProduct.nodes[0]
        );

        let matchStatus;

        // CHECK THE CART FOR MAIN PRODUCT AND THIS PRODUCT IS NOT PRESENT
        matchStatus = cart.items.some(
          (data: any) => (data.productId || data.databaseId) == mainProductId
        );

        if (!matchStatus) {
          navigate("/" + location.search);
        }
      }

      // ELSE TRUE THEN PRODUCT IS NOT DISCOUNTED

      // NEW SECTION ADDED
      if (data?.data?.grouped_products.length > 0) {
        const linkedProducts = data?.data?.grouped_products;
        const productsWithVariants = await Promise.all(
          linkedProducts.map(async (product: any) => {
            const result = product.varient;
            return { ...product, varient: result };
          })
        );

        setGroupedProduct(productsWithVariants);
        const selectedOptions: any = {};
        const selectedProductPrice: any = {};

        productsWithVariants.forEach((product: any) => {
          product.attributes.forEach((attr: any) => {
            selectedOptions[product.id] = {
              ...selectedOptions[product.id],
              [attr.name]: attr.options[0],
            };
          });

          const sortedVariant = product.varient.sort(
            (a: any, b: any) => Number(a.price) - Number(b.price)
          );
          selectedProductPrice[product.id] = {
            price: sortedVariant?.[0]?.sale_price || sortedVariant?.[0]?.price,
          };
        });

        setSelectedOptions(selectedOptions);
        setSelectedProductPrice(selectedProductPrice);
        // INITIAL IMAGE PROCESS FOR GROUP PRODUCT
        initialImage = [data.data.images[0]];
        if (productAdditionalData?.productVideoGallery) {
          const videoID = getYouTubeVideoID(
            productAdditionalData?.productVideoGallery.featuredYoutubeVideo
          );

          const status = initialImage.some((el: any) => el.type === "video");

          if (videoID !== null && !status) {
            initialImage.push({
              id: 18263,
              type: "video",
              src: `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`,
              url: productAdditionalData?.productVideoGallery
                .featuredYoutubeVideo,
              name: "video",
              alt: "video",
            });
            setVideoDetails({
              id: 18263,
              type: "video",
              src: `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`,
              url: productAdditionalData?.productVideoGallery
                .featuredYoutubeVideo,
              name: "video",
              alt: "video",
            });
          } else {
            setVideoDetails(false);
          }

          setAllImages(initialImage);
        } else {
          setAllImages(initialImage);
        }
      } else if (data?.data?.type === "variable") {
        // const variants = await getProductVariants(data.data.id);
        const variants = data.data.product_variations;
        const sortedVariant = variants.sort(
          (a: any, b: any) => Number(a.price) - Number(b.price)
        );
        setVarient(sortedVariant);
        setSelectedVarient(sortedVariant[0]);
        setProductPrice(sortedVariant[0]?.price);
        setProductPriceLimit(
          `$${Number(sortedVariant[0].price).toFixed(2)} - $${Number(
            sortedVariant[sortedVariant.length - 1].price
          ).toFixed(2)}`
        );

        setAllVarients(sortedVariant);

        const varientdata = await processProduct(
          sortedVariant,
          data?.data?.attributes?.[0].options[0]
        );

        setSelectedVarient(varientdata[0]);
        setProductPrice(varientdata?.[0]?.sale_price);
        setVarient(varientdata);

        // INITIAL IMAGE PROCESS FOR VARIENT PRODUCT

        const firstImage = varientdata.map((p: any) => {
          return p.image;
        });
        initialImage = [firstImage[0]];
        if (productAdditionalData?.productVideoGallery) {
          const videoID = getYouTubeVideoID(
            productAdditionalData?.productVideoGallery.featuredYoutubeVideo
          );

          console.log(initialImage, "initialImage");
          const status = initialImage?.some((el: any) => el?.type === "video");

          if (videoID !== null && !status) {
            initialImage.push({
              id: 18263,
              type: "video",
              src: `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`,
              url: productAdditionalData?.productVideoGallery
                .featuredYoutubeVideo,
              name: "video",
              alt: "video",
            });
            setVideoDetails({
              id: 18263,
              type: "video",
              src: `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`,
              url: productAdditionalData?.productVideoGallery
                .featuredYoutubeVideo,
              name: "video",
              alt: "video",
            });
          } else {
            setVideoDetails(false);
          }

          setAllImages(initialImage);
        } else {
          setAllImages(initialImage);
        }
      } else {
        const productDetails: ProductType = data?.data;
        const price = productDetails?.sale_price
          ? productDetails?.sale_price
          : productDetails?.price;
        setProductPrice(price);
        let initialImage: any = productDetails?.images;

        if (productAdditionalData?.productVideoGallery) {
          const videoID = getYouTubeVideoID(
            productAdditionalData?.productVideoGallery.featuredYoutubeVideo
          );

          const status = initialImage?.some((el: any) => el?.type === "video");

          if (videoID !== null && !status) {
            initialImage.push({
              id: 18263,
              type: "video",
              src: `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`,
              url: productAdditionalData?.productVideoGallery
                .featuredYoutubeVideo,
              name: "video",
              alt: "video",
            });
            setVideoDetails({
              id: 18263,
              type: "video",
              src: `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`,
              url: productAdditionalData?.productVideoGallery
                .featuredYoutubeVideo,
              name: "video",
              alt: "video",
            });
          } else {
            setVideoDetails(false);
          }
          setAllImages(initialImage);
        } else {
          setAllImages(initialImage);
        }
      }
      setLoading(false);

      setRelatedProduct(data.data.related_products);
    };

    fetchProducts();
  }, [props.location.state, cart.items]);

  useEffect(() => {
    if (groupedProduct?.length > 0) {
      const range = calculatePriceRange(groupedProduct);
      setPriceRange(range);
    }
  }, [groupedProduct]);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const varButton = varButtonRef.current;
      const productDetailsSec = productDetailsSecRef.current;

      if (varButton && productDetailsSec) {
        const varButtonRect = varButton.getBoundingClientRect();
        const productDetailsSecRect = productDetailsSec.getBoundingClientRect();

        if (varButtonRect.bottom < window.innerHeight) {
          varButton.classList.add("sticky");
        } else if (varButtonRect.bottom == window.innerHeight) {
          varButton.classList.remove("sticky");
        } else {
          varButton.classList.remove("sticky");
        }
      }
    }, 100); // adjust the throttle time as needed

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSelect = (value: any) => {
    setSelectedVarient(value);
    setProductPrice(value?.sale_price);
  };

  const handleAddToCart = async (
    product: ProductType,
    variant: any,
    quantity: number = 1
  ) => {
    setblock(true);
    if (groupedProduct?.length > 0) {
      setblock(true);
      let obj: any = {
        id: uniqueId(),
        productId: product.id,
        name: product.name,
        slug: product.slug,
        price: Number(productPrice),
        thumbnail: product.images?.[0]?.src,
        variant: variant,
        quantity: inputValue ? Number(inputValue) : quantity,
        categories: product.categories,
        linkedProducts: [],
      };
      groupedProduct.map(async (product) => {
        const variantData: any = await groupProductProcess(
          product,
          selectedOptions
        );
        const newObj = {
          id: uniqueId(),
          productId: product.id,
          name: product.name,
          slug: product.slug,
          price: Number(variantData?.sale_price),
          thumbnail: product.images[0].src,
          variant: variantData,
          quantity: inputValue ? Number(inputValue) : quantity,
          categories: product.categories,
        };
        obj.linkedProducts = [...obj.linkedProducts, newObj];
      });

      await dispatch(
        checkAndAddToCart({
          item: obj,
          userId: userId,
          quantity: Number(inputValue),
        })
      );
      setblock(true);
    } else {
      console.log(product, additionalData, "meytwefrgyf");
      const obj = {
        id: uniqueId(),
        productId: product.id,
        name: product.name,
        slug: product.slug,
        price: Number(product.price),
        thumbnail: product.images?.[0]?.src,
        variant: variant,
        quantity: inputValue ? Number(inputValue) : quantity,
        categories: product.categories,
        parentId: additionalData?.databaseId ?? null,
      };

      console.log(additionalData);

      const discountProductFlag = product.categories.some(
        (data: any) => data.slug == "discounted-upsell-products"
      );

      //   console.log(cart,"discountProductFlagdiscountProductFlag")

      if (discountProductFlag) {
        console.log("first");
        if (cart.items.some((data) => data.productId)) {
          await dispatch(
            checkAndAddToCart({
              item: obj,
              userId: userId,
              quantity: Number(inputValue),
            })
          );
        } else {
          toast.error("Product can not be added.");
        }
      } else {
        await dispatch(
          checkAndAddToCart({
            item: obj,
            userId: userId,
            quantity: Number(inputValue),
          })
        );
      }
    }
    dispatch(slideToggle());
    setblock(false);
    // toast.success("Item added to cart");
  };

  const handleOptionChange = async (
    product: any,
    attributeName: string,
    value: any
  ) => {
    const obj = {
      [product.id]: {
        ...selectedOptions[product.id],
        [attributeName]: value,
      },
    };
    const variantData: any = await groupProductProcess(product, obj);

    setSelectedOptions((prev: any) => ({
      ...prev,
      [product.id]: {
        ...prev[product.id],
        [attributeName]: value,
      },
    }));

    setSelectedProductPrice((prev: any) => ({
      ...prev,
      [product.id]: {
        ...prev[product.id],
        price: variantData?.sale_price || variantData?.price,
      },
    }));
  };

  useEffect(() => {
    let price: any = 0.0;
    if (Object.keys(selectedProductPrice)?.length > 0) {
      Object.keys(selectedProductPrice).map((key: any) => {
        price += Number(selectedProductPrice[key].price);
      });
      setProductPrice(price.toFixed(2));
    }
  }, [selectedProductPrice]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("size")) {
      navigate(``);
    }
  }, []);

  return (
    <Layout>
      {/* {seoInfo && <Seo info={seoInfo} />} */}
      <section className="px-[15px] pt-0 pb-6  md:px-[27px] lg:px-[88px] contain">
        <Breadcrumb crumbs={crumbs} />
        <div className="mx-auto">
          <div className="flex flex-wrap">
            <div className="slg:w-[55%] lg:w-[50%] w-full">
              <div className="w-full ">
                <aside className="w-full lg:w-[95%] ">
                  {!product?.images ? (
                    <div className=" lg:min-h-[510px] min-h-[230px] flex justify-center items-center">
                      <PageLoader />
                    </div>
                  ) : (
                    <div className="w-full">
                      <ImageGallery images={allImages} />
                    </div>
                  )}
                  {/* LIGHT BOX END */}
                </aside>

                {/* mobile-view for product details */}

                <div className="lg:hidden block">
                  {loading ? (
                    <div className="min-h-[130px] ">
                      <ProductDetailsLoader />
                    </div>
                  ) : (
                    <div className="w-full">
                      <div className="w-full  flex flex-col">
                        <h1 className="text-[28px] font-bold text-gray-900 min-h-[28px] ">
                          {product?.name}
                        </h1>
                        {/* REVIEW START */}
                        {/* <Review reviewData={4.5} /> */}

                        <div className="my-2 yopto-rw min-h-[17px]">
                          {product?.id ? (
                            <StarReview productId={product?.id} />
                          ) : (
                            "Loading.."
                          )}
                        </div>
                        {/* REVIEW END */}
                        {/* pricing */}
                        {product?.price ? (
                          <div className="mb-2 block">
                            <span className="font-sofiaSemibold font-semiBold text-primary min-h-[20px] text-[20px]">
                              {groupedProduct?.length > 0
                                ? `$${productPrice}`
                                : product?.type === "variable"
                                ? productPricelimit
                                : `$${Number(
                                    product?.sale_price || product?.price
                                  ).toFixed(2)}`}
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div
                          className={`flex items-center lg:hidden justify-between min-h-[55px]`}
                        >
                          {/* selction start */}
                          {product?.attributes?.[0]?.options.length > 1 ? (
                            <div className="mt-[5px] mb-[10px] lg:hidden flex items-center justify-center">
                              <label
                                className="mr-3 font-sofiaBold font-bold capitalize text-[13px] "
                                htmlFor="sort-direction"
                              >
                                {product?.attributes?.[0].name}
                                <span>:</span>
                              </label>
                              <select
                                value={selectedOption}
                                onChange={handleVarientChange}
                                id="sort-direction"
                                className="  bg-bg-grey h-[40px] rounded-[12px] text-[14px]  px-3 font-sofiaRegular font-regular focus:outline-none border w-[120px]"
                              >
                                {product?.attributes?.[0]?.options?.map(
                                  (data: any) => {
                                    return (
                                      <option value={data} key={data}>
                                        {data}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                          ) : (
                            <></>
                          )}
                          {product?.attributes?.[0]?.options.length == 1 ? (
                            <div className="mt-[5px] mb-[10px]  md:hidden block ">
                              <label
                                className="mr-1 capitalize font-sofiaBold font-bold text-[16px]"
                                htmlFor="sort-direction"
                              >
                                {product?.attributes?.[0].name}
                                <span>:</span>
                              </label>
                              <span className=" font-sofiaMedium text-[16px]">
                                {product?.attributes?.[0]?.options[0]}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                          {/* selection end */}
                          <div
                            className={`w-1/2 md:hidden flex  md:pt-0 ${
                              groupedProduct?.length > 0
                                ? "justify-start "
                                : "justify-end"
                            } `}
                          >
                            <ProductDetailsCounter
                              id="mobile"
                              initialQuantity={1}
                              setInputValue={setInputValue}
                              inputValue={inputValue}
                            />
                          </div>

                          {/* counter end */}
                        </div>
                        {/* pricing end */}
                        {/* short description */}
                        {product?.short_description ? (
                          <div
                            className="mt-3 mb-3 pro-det-text pro-det-text"
                            dangerouslySetInnerHTML={{
                              __html: product?.short_description,
                            }}
                          />
                        ) : (
                          // <TruncatedHtml html={product.short_description} />
                          <div className="h-[100px]"></div>
                        )}
                      </div>
                      {/* security-card */}
                      <div className="md:hidden block border-t pt-3">
                      <div className="bg-[#F5F5F5] md:px-[8px] px-[15px] py-[10px] lg:rounded-[36px] rounded-[14px] lg:mt-[15px] mt-[10px] ">
                      <ul className="flex md:flex-row flex-col md:justify-center justify-start md:items-center gap-1 trans-details">
                        <li>
                            <span className="w-30px"><Shipping/></span>Free shipping over $100
                        </li>
                        <li>
                         <span className="w-30px"><SeaSame/></span> US compounding Pharmacy
                        </li>
                        <li>
                          <span className="w-30px"><PPolicy/></span>Privacy Guarantee
                        </li>
                      </ul>
                    </div>
                      </div>
                     
                     {/* security-card */}
                      {/* bundle product */}
                      {groupedProduct?.length > 0 ? (
                        <div className="lg:hidden block bg-bg-grey mt-[30px] px-4 py-5 rounded-[12px]">
                          {groupedProduct && groupedProduct?.length > 0 ? (
                            groupedProduct.map((product) => {
                              return (
                                <div
                                  key={product.id}
                                  className="flex justify-between items-center cursor-pointer border-b last:border-b-0 pb-2 last:pb-0 mb-2 last:mb-0"
                                >
                                  <div className="flex justify-start items-center gap-6 w-full">
                                    <div
                                      className="w-[70px] h-[70px] bg-white p-2 flex items-center rounded-[9px]"
                                      onClick={() =>
                                        navigate(`/products/${product.slug}`, {
                                          state: {
                                            ...props.location.state,
                                            productName: product.name,
                                            productId: product.id,
                                          },
                                        })
                                      }
                                    >
                                      <img
                                        src={product.images[0].src}
                                        alt={product.images[0].alt}
                                        width={50}
                                        height={50}
                                        className="rounded object-contain w-[50px] h-[50px] "
                                      />
                                    </div>
                                    <div className="w-full">
                                      <div
                                        className="flex justify-between items-start w-full"
                                        onClick={() =>
                                          navigate(
                                            `/products/${product.slug}`,
                                            {
                                              state: {
                                                ...props.location.state,
                                                productName: product.name,
                                                productId: product.id,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        <p className="font-sofiaRegular font-semiBold  text-[15px]">
                                          {product.name}
                                        </p>
                                        <p className="font-sofiaRegular font-semiBold  text-[15px]">
                                          {selectedProductPrice[product.id]
                                            ?.price
                                            ? `$${Number(
                                                selectedProductPrice[product.id]
                                                  ?.price
                                              ).toFixed(2)}`
                                            : `$${Number(
                                                product.varient?.[0].sale_price
                                              ).toFixed(2)}`}
                                        </p>
                                      </div>
                                      <div className="flex flex-col justify-center mt-2  gap-1">
                                        {/* Bundle product implementation */}
                                        {product?.attributes?.map(
                                          (attribute: any, index: any) => {
                                            const unique_id = uniqueId();
                                            return (
                                              <div
                                                className="flex justify-between items-center"
                                                key={index}
                                              >
                                                <label
                                                  htmlFor={`${unique_id}-select`}
                                                  className="mr-3 text-[14px] font-sofiaRegular font-regular"
                                                >
                                                  {capitalizeFirstLetter(
                                                    attribute?.name
                                                  )}
                                                  <span>:</span>
                                                </label>
                                                <select
                                                  id={`${unique_id}-select`}
                                                  value={
                                                    selectedOptions?.[
                                                      product.id
                                                    ]?.[attribute.name] || ""
                                                  }
                                                  onChange={(e) => {
                                                    handleOptionChange(
                                                      product,
                                                      attribute.name,
                                                      e.target.value
                                                    );
                                                  }}
                                                  className="w-[100px] bg-bg-grey h-[34px] text-[14px] rounded-[3px]  px-1 font-sofiaRegular font-regular focus:outline-none border "
                                                >
                                                  {attribute?.options?.map(
                                                    (option: string) => (
                                                      <option
                                                        value={option}
                                                        key={option}
                                                      >
                                                        {option}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}

                      {/* short description */}
                      {/* Key features */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product?.acf?.key_points,
                        }}
                        className="key-text"
                      ></div>
                      {/* Key features */}
                    </div>
                  )}
                </div>
                {/* !end mobile-view for product details */}

                <div
                  className="md:mt-[50px] mt-[20px] md:pt-[40px]  pt-[20px] w-full relative product-details-sec "
                  ref={productDetailsSecRef}
                >
                  <div className="w-[115%] absolute h-[14px] left-[-12%] top-0 bg-[#F6F6F6] "></div>
                  {loading ? (
                    <ProductLongLoader />
                  ) : (
                    <div className="w-full">
                      <h2 className="font-sofiaSemibold font-semiBold mt-4 md:text-[40px] text-[28px] text-black ">
                        Product details
                      </h2>

                      {product?.description ? (
                        <div
                          className="mt-4 mb-6 pro-details"
                          dangerouslySetInnerHTML={{
                            __html: product?.description,
                          }}
                        ></div>
                      ) : (
                        <div className="mt-4 mb-6 pro-details"></div>
                      )}
                      {/*large review */}

                      <div className="pro-review">
                        <div
                          className="yotpo yotpo-main-widget p-7"
                          data-product-id={product?.id}
                          data-price={product?.price}
                          data-currency="USD"
                          data-name={product?.name}
                          data-url={`https://spartanpeptides.com/products/${product.slug}`}
                          data-image-url={product.images?.[0]?.src}
                        ></div>
                      </div>

                      {/* large review */}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <main className="w-full slg:w-[45%] lg:w-[50%] px-5">
              {/* pageloader */}
              {loading ? (
                <div className="md:block hidden">
                  <ProductDetailsLoader />
                </div>
              ) : (
                <>
                  <div className="w-full md:relative  bg-white lg:pl-3 lg:block hidden">
                    <h1 className="text-[35px] mt-[-15px] font-sofiaSemibold font-semiBold text-black ml-[-4px] lg:block hidden min-h-[40px]">
                      {product?.name}
                    </h1>
                    {/* REVIEW START */}

                    <div className="lg:block my-1 yopto-rw min-h-[17px]">
                      {/* <Review reviewData={4.5} /> */}
                      {product?.id ? (
                        <StarReview productId={product?.id} />
                      ) : (
                        // <div
                        //   className="yotpo bottomLine"
                        //   data-product-id={product?.id}
                        //   data-url="#d"
                        //   style={{
                        //     height: "50px", // Fixed height for the Yotpo widget
                        //     width: "100%",  // Fixed width, using 100% to match the container
                        //     overflow: "hidden", //r layout's width
                        //   }}
                        // ></div>
                        <div
                          style={{
                            height: "50px", // Same height as the Yotpo widget to maintain consistency
                            width: "100%", // Same width
                          }}
                        ></div>
                      )}
                    </div>

                    {/* REVIEW END */}

                    {/* Short description start */}
                    {product.short_description ? (
                      <TruncatedHtml html={product.short_description} />
                    ) : (
                      <div style={{ minHeight: "60px" }}></div>
                    )}
                    {/* Short description end */}
                  </div>

                  {/* end */}
                  {/* varient product start */}
                  <div
                    className="w-full  var-button   lg:pb-0   left-0 pb-[10px] bg-white z-30 px-3 dropshadow-cust"
                    ref={varButtonRef}
                  >
                    {product?.price ? (
                      <div className="mb-1 lg:block hidden">
                        <span className="font-sofiaSemibold font-semiBold text-primary xl:text-[25px] text-[20px] min-h-[25px]">
                          {groupedProduct?.length > 0
                            ? `$${productPrice}`
                            : product.type === "variable"
                            ? productPricelimit
                            : `$${Number(
                                product?.sale_price || product.price
                              ).toFixed(2)}`}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}

                    {product?.attributes?.[0]?.options.length > 1 ? (
                      <div className=" min-h-[30px] mb-[10px] lg:block hidden mt-[5px]">
                        <label
                          className="mr-3 font-sofiaBold font-bold capitalize text-[16px] "
                          htmlFor="sort-direction"
                        >
                          {product?.attributes?.[0].name}
                          <span>:</span>
                        </label>
                        <select
                          value={selectedOption}
                          onChange={handleVarientChange}
                          id="sort-direction"
                          className="  bg-bg-grey h-[40px] rounded-[12px] text-[14px]  px-3 font-sofiaRegular font-regular focus:outline-none border md:mt-0 mt-5 w-[160px]"
                        >
                          {product?.attributes?.[0]?.options?.map(
                            (data: any) => {
                              return (
                                <option value={data} key={data}>
                                  {data}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    ) : (
                      <></>
                    )}
                    {product?.attributes?.[0]?.options.length == 1 ? (
                      <div className="mt-[5px] mb-[10px] lg:block hidden  ">
                        <label
                          className="mr-1 capitalize font-sofiaBold font-bold text-[16px]"
                          htmlFor="sort-direction"
                        >
                          {product?.attributes?.[0].name}
                          <span>:</span>
                        </label>
                        <span className=" font-sofiaMedium text-[16px]">
                          {product?.attributes?.[0]?.options[0]}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* bundle product */}
                    {groupedProduct?.length > 0 ? (
                      <div className="lg:block hidden bg-bg-grey px-4 py-2 rounded-[12px] h-[210px] overflow-y-auto">
                        <div className="h-full">
                          {groupedProduct && groupedProduct?.length > 0 ? (
                            groupedProduct.map((product) => {
                              return (
                                <div
                                  key={product.id}
                                  className="flex justify-between items-center cursor-pointer border-b last:border-b-0 pb-2 last:pb-0 mb-2 last:mb-0"
                                >
                                  <div className="flex justify-start items-center gap-6 w-full">
                                    <div
                                      className="w-[50px] h-[50px] bg-white p-2 flex items-center rounded-[9px]"
                                      onClick={() =>
                                        navigate(`/products/${product.slug}`, {
                                          state: {
                                            ...props.location.state,
                                            productName: product.name,
                                            productId: product.id,
                                          },
                                        })
                                      }
                                    >
                                      <img
                                        src={product.images[0].src}
                                        alt={product.images[0].alt}
                                        width={50}
                                        height={50}
                                        className="rounded object-contain w-[50px] h-[50px] "
                                      />
                                    </div>
                                    <div className="w-full">
                                      <div
                                        className="flex justify-between items-center w-full"
                                        onClick={() =>
                                          navigate(
                                            `/products/${product.slug}`,
                                            {
                                              state: {
                                                ...props.location.state,
                                                productName: product.name,
                                                productId: product.id,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        <p className="font-sofiaRegular font-regular xl:text-[15px] text-[13px]">
                                          {product.name}
                                        </p>
                                        <p className="font-sofiaRegular font-regular xl:text-[15px] text-[13px]">
                                          {selectedProductPrice[product.id]
                                            ?.price
                                            ? `$${Number(
                                                selectedProductPrice[product.id]
                                                  ?.price
                                              ).toFixed(2)}`
                                            : `$${Number(
                                                product.varient?.[0].sale_price
                                              ).toFixed(2)}`}
                                        </p>
                                      </div>
                                      <div className="flex items-center gap-4">
                                        {/* Bundle product implementation */}
                                        {product?.attributes?.map(
                                          (attribute: any, index: any) => {
                                            const unique_id = uniqueId();
                                            return (
                                              <div key={index}>
                                                <label
                                                  htmlFor={`${unique_id}-select`}
                                                  className="mr-3 font-sofiaRegular font-regular xl:text-[15px] text-[13px]"
                                                >
                                                  {capitalizeFirstLetter(
                                                    attribute?.name
                                                  )}
                                                  <span>:</span>
                                                </label>
                                                <select
                                                  id={`${unique_id}-select`}
                                                  value={
                                                    selectedOptions?.[
                                                      product.id
                                                    ]?.[attribute.name] || ""
                                                  }
                                                  onChange={(e) => {
                                                    handleOptionChange(
                                                      product,
                                                      attribute.name,
                                                      e.target.value
                                                    );
                                                  }}
                                                  className="w-fit bg-bg-grey h-[34px] rounded-[3px] xl:text-[15px] text-[13px] px-1 font-sofiaRegular font-regular focus:outline-none border md:mt-0 mt-5"
                                                >
                                                  {attribute?.options?.map(
                                                    (option: string) => (
                                                      <option
                                                        value={option}
                                                        key={option}
                                                      >
                                                        {option}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* bundle product */}
                    {varients?.length > 0 ? (
                      <div className="md:py-4 pt-6 pb-1 md:border-y overflow-x-auto w-full md:min-h-[194px] ">
                        {/* VARIENT START */}
                        <Varient
                          varients={varients}
                          selectedVarient={selectedVarient}
                          handleSelect={handleSelect}
                        />
                        {/* VARIENT END  */}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product?.acf?.key_points,
                      }}
                      className="key-text md:block hidden"
                    ></div>
                    {product &&
                    (varients.length > 0 ||
                      groupedProduct.length > 0 ||
                      product.type === "simple") ? (
                      <div className="mt-2 relative items-center justify-between flex gap-2 lg:min-h-[54px] ">
                        <div className="w-[30%] md:block hidden">
                          <div className="">
                            <ProductDetailsCounter
                              initialQuantity={1}
                              setInputValue={setInputValue}
                              inputValue={inputValue}
                            />
                          </div>
                        </div>

                        <div className="lg:w-[70%] md:w-[60%] w-full">
                          {" "}
                          <button
                            className="w-full h-[54px]  bg-[#000000] hover:bg-primary text-white font-bold py-2 px-4 relative rounded-full transition-all ease-in disabled:bg-gray-400 disabled:cursor-not-allowed"
                            disabled={Block || props.data.data.stock_status === "outofstock" ? true : false }
                            onClick={() =>
                              product?.button_text
                                ? window.open(
                                    product.external_url,
                                    "_blank",
                                    "noopener,noreferrer"
                                  )
                                : handleAddToCart(product, selectedVarient)
                            }
                          >
                            {product?.button_text ? (
                              <div className="lg:text-[20px] md:text-[18px] text-white font-sofiaRegular font-regular">
                                {product.button_text}
                              </div>
                            ) : groupedProduct?.length > 0 ? (
                              <div className="lg:text-[20px] md:text-[18px] text-white font-sofiaRegular font-regular">
                                {Block ? "Adding..." : "Add to cart"}
                              </div>
                            ) : (
                              <div
                                className={`flex justify-between items-center`}
                              >
                                {Block ? (
                                  <div className="lg:text-[20px] md:text-[18px] text-white font-sofiaRegular font-regular">
                                    Adding...
                                  </div>
                                ) : (
                                  <>
                                    <div className="lg:text-[15px] xl:text-[20px] text-white font-sofiaRegular font-regular">
                                      Add to cart
                                    </div>
                                    <div className="lg:text-[15px] xl:text-[20px] text-white font-sofiaRegular font-regular">
                                      $
                                      {!productPrice
                                        ? Number(product?.price).toFixed(2) ||
                                          "0.00"
                                        : Number(productPrice).toFixed(2)}
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* end varient and button */}
                    <div className="flex payment-card mt-4 justify-center">
                      <ul className="flex gap-2">
                        <li className="w-[38px] border">
                          <img
                            src={visa}
                            alt={"visa card"}
                            className="w-full contain"
                            style={{
                              cursor: "pointer",
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </li>
                        <li className="w-[38px] border">
                          <img
                            src={vimeo}
                            alt={"visa card"}
                            className="w-full contain"
                            style={{
                              cursor: "pointer",
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </li>
                        <li className="w-[38px] border">
                          <img
                            src={masterCard}
                            alt={"visa card"}
                            className="w-full contain"
                            style={{
                              cursor: "pointer",
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </li>
                        <li className="flex items-center font-sofiaRegular font-medium md:text-[14px]  text-[13px]">
                          <span className="w-[20px] h-[20px]">
                            <Lock />
                          </span>
                          Secure Checkout
                        </li>
                      </ul>
                    </div>
                    {/* payment-card */}
                    {/*  */}
                    <div className="bg-[#F5F5F5] md:px-[8px] px-[15px] py-[10px] lg:rounded-[36px] rounded-[14px] lg:mt-[15px] mt-[10px] md:block hidden">
                      <ul className="flex md:flex-row flex-col md:justify-center justify-start md:items-center gap-1 trans-details">
                        <li>
                            <span className="w-30px"><Shipping/></span>Free shipping over $100
                        </li>
                        <li>
                         <span className="w-30px"><SeaSame/></span> US compounding Pharmacy
                        </li>
                        <li>
                          <span className="w-30px"><PPolicy/></span>Privacy Guarantee
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
              {/* )} */}
            </main>
            {/* PRODUCT DETAILS START  */}

            {/* PRODUCT DETAILS END  */}
          </div>
        </div>
      </section>
      {/* RELATED PRODUCTS START */}
      {relatedProduct?.length > 0 ? (
        <ProductCarousel
          isShowAddToCartBtn={false}
          productList={relatedProduct}
          title={"Related products"}
        />
      ) : (
        <></>
      )}
      {/* RELATED PRODUCTS END */}
    </Layout>
  );
}

export const query = graphql`
  query ProductDetails($id: Int) {
    productAdditionalData: wpProduct(databaseId: { eq: $id }) {
      id
      productVideoGallery {
        featuredYoutubeVideo
      }
      productSchemaInformation {
        productSchema
      }
      originProduct {
        isDiscounted
        mainProduct {
          nodes {
            id
            slug
            status
            uri
            databaseId
          }
        }
      }
    }

    data: wcProducts(wordpress_id: { eq: $id }) {
      id: wordpress_id
      stock_status
      name
      description
      short_description
      price
      regular_price
      sale_price
      slug
      variations
      yoast_head
      categories {
        id
        name
        slug
      }
      images {
        id
        name
        alt
        src
      }
      type
      attributes {
        id
        name
        options
      }
      grouped_product_ids: grouped_products
      grouped_products: grouped_products_nodes {
        id: wordpress_id
        name
        price
        sale_price
        regular_price
        slug
        attributes {
          id
          name
          options
        }
        images {
          id
          name
          src
          alt
        }
        varient: product_variations {
          id
          name
          price
          sale_price
          regular_price
          type
          image {
            id
            name
            src
            alt
          }
          attributes {
            id
            name
            option
          }
          on_sale
        }
      }
      product_variations {
        id
        name
        price
        sale_price
        regular_price
        type
        image {
          id
          name
          src
          alt
        }
        attributes {
          id
          name
          option
        }
        on_sale
      }
      related_products {
        id
        name
        price
        sale_price
        regular_price
        slug
        images {
          id
          name
          src
          alt
        }
      }
    }
  }
`;

export const Head: HeadFC = (props: any) => {
  const parseHtml: any = parse(props.data.data.yoast_head);
  const schema: any =
    props.data.productAdditionalData.productSchemaInformation.productSchema;
  console.log(schema, "===>>,,,,");
  // const parseHtmlSchema: any = parse(schema);

  // console.log(schema, "====")
  // console.log(parseHtmlSchema, "parseHtmlSchema")

  const index =
    parseHtml &&
    parseHtml?.length > 0 &&
    parseHtml.findIndex(
      (item: any) => item?.props?.type === "application/ld+json"
    );

  if (index > -1) {
    parseHtml.splice(index, 1);
  }

  return (
    <>
      {parseHtml}
      <Seo Schema={schema} info={props.pageContext.yoast_head} />
    </>
  );
};

async function processProduct(productData: any, attr: any) {
  console.log(productData, "<<-- productData");
  const varient = productData;
  if (varient.length) {
    const matchAttr = varient.filter((data: any) => {
      if (data.attributes?.[0].option == attr) {
        return data;
      }
    });
    // console.log(matchAttr, "<<-- matchAttr");

    const sortedByVials = matchAttr.sort(
      (a: { attributes: any[] }, b: { attributes: any[] }) => {
        const aVials = a.attributes.find(
          (attr) => attr.name === "vials"
        )?.option;
        const bVials = b.attributes.find(
          (attr) => attr.name === "vials"
        )?.option;

        return parseInt(aVials) - parseInt(bVials);
      }
    );

    return sortedByVials;
  }
}

async function groupProductProcess(product: any, object: any) {
  let obj = object[product.id];
  const varient = product?.varient;
  if (varient.length) {
    // Function to check if an object's attributes match the desired attributes
    const matchAttributes = (attributes: any, desiredAttributes: any) => {
      return Object.keys(desiredAttributes).every((key) =>
        attributes.some(
          (attr: any) =>
            attr.name === key && attr.option === desiredAttributes[key]
        )
      );
    };

    // Find the variation that matches the desired attributes
    const findMatchingVariation = (desiredAttributes: any) => {
      return varient.find((variation: any) =>
        matchAttributes(variation.attributes, desiredAttributes)
      );
    };

    const result = findMatchingVariation(obj);
    // console.log(result, "<<-- result");
    return result;
  }
}
