import React, { FC, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { getQueryString } from "../../utils/helper";
interface imagesObj {
  src: string;
  alt: string;
}

interface props {
  acf?: any;
  images: imagesObj[] | any;
}

const ImageGallery: FC<props> = ({ acf, images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLightboxOpen, setLightboxOpen] = useState(false);

  const currentMedia = images?.[currentIndex];

  // Open the lightbox with the selected image/video
  const onSelectMedia = (index: number) => {
    setCurrentIndex(index);
  };

  const onSelectMediaMain = (index: number) => {
    setCurrentIndex(index);
    setLightboxOpen(true);
  };

  // Close the lightbox
  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  // Navigate to the next image/video
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Navigate to the previous image/video
  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className=" flex  md:flex-row-reverse flex-col md:gap-x-5 gap-y-4">
      {/* Main Gallery Section */}
      <div className="md:w-[89%]  w-full px-5 md:mb-5 lg:mb-0  lg:pt-[21px] lg:pb-[21px] pt-[10px]  lg:rounded-[21px] rounded-[12px] min-h-[200px] lg:min-h-[450px] bg-helmet">
      <div className="flex justify-center lg:h-[450px] h-[200px] pro-ban ">
        {currentMedia?.type ? (
          <>
            <ReactPlayer
              className="react-player"
              url={currentMedia.url}
              width={"640px"}
              height={"360px"}
              controls={true}

            />
          </>
        ) : (
          <>
            <img
              src={currentMedia?.src}
              alt={currentMedia?.alt}
              className=""
              style={{
                cursor: "pointer",
                height: "100%",
                width: "100%",
                objectFit: "contain",
                margin: "5px",
              }}
              onClick={() => onSelectMediaMain(currentIndex)}
            />
          </>
        )}
        
      </div>
      </div>
     
      {/* Thumbnail Section */}
      <div
        className={`pro-thumb md:flex-col flex-row md:gap-5 gap-1`}
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {images && images?.length > 0 && images?.map((img: any, index: any) => (
          <div className={`${img?.type ? " video-style" : " image-style"}`} key={index}>

            
            <img
              key={index}
              onClick={() => onSelectMedia(index)}
              className={`lg:w-[100px] mx-1 relative cursor-pointer bg-bg-grey w-[60px] object-cover lg:h-[100px] h-[60px] lg:border-[6px] border-[2px] p-1  ${currentIndex === index ? "border-[#868585]" : "border-[#ddd]"
                }${img?.type ? " video-box" : " image-box"}`}
              src={img?.src}
              alt={`Thumbnail ${index + 1}`}
            />
          </div>


        ))}
      </div>
      

      {/* Lightbox Overlay */}
      {isLightboxOpen && (
        <>
          <div
            className="fixed inset-0 z-50 bg-black bg-opacity-[0.9] overlay-bg flex justify-center items-center"
            onClick={closeLightbox}
          >
            <div className="flex justify-center items-center">
              {/* Previous Button */}
              <button
                className="absolute left-[2%] text-white text-3xl flex items-start justify-center  w-[50px] h-[50px]   rounded-full cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePrev();
                }}
              >
                <span className="text-[70px] ">&#8249;</span>
              </button>

              {/* Display video or image */}

              {currentMedia.type ? (
                <>
                  <ReactPlayer
                    className="react-player"
                    url={currentMedia.url}
                    controls={true}
                    width={"640px"}
                    height={"360px"}
                  />
                </>
              ) : (
                <>
                  <img
                    src={currentMedia.src}
                    alt="Enlarged"
                    className="max-w-[90%] max-h-[90%] cursor-pointer"
                  />
                </>
              )}

              {/* Next Button */}
              <button
                className="absolute right-[2%] text-white text-3xl  w-[50px] h-[50px] block items-start justify-center  rounded-full cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNext();
                }}
              >
                <span className="text-[70px] leading-[30px]">&#8250;</span>
              </button>
              {/* Close Button */}
              <span
                className="absolute top-5 right-5 text-white text-3xl cursor-pointer"
                onClick={closeLightbox}
              >
                &times;
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageGallery;
